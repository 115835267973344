<template>
  <div class="operate-btn">
    <div
      class="oa-add-invoice-bottom"
      v-if="
        isAdd === 0 &&
          progress !== 'APPROVED' &&
          Approval.length > 0 &&
          CC.length > 0
      "
    >
      <!-- <button class="oa-button-save" @click="save('UNSUBMENT')">保存</button> -->
      <button class="oa-button-apply" @click="save('UNAUDITED')">申请</button>
    </div>
    <div
      class="oa-add-invoice-bottom"
      v-if="isAdd === 1 && progress !== 'APPROVED'"
    >
      <!-- <button
        class="oa-button-save"
        @click="save('UNSUBMENT')"
        v-if="progress === 'UNSUBMENT'"
      >
        保存
      </button> -->
      <button
        class="oa-button-delete"
        @click="save('DELETE')"
        v-if="progress === 'UNSUBMENT' || progress === 'CERTIFIED_FAILED'"
      >
        删除
      </button>
      <button
        class="oa-button-apply"
        @click="save('UNAUDITED')"
        v-if="progress === 'UNSUBMENT' || progress === 'CERTIFIED_FAILED'"
      >
        申请
      </button>
      <button
        class="oa-button-save"
        @click="save('CERTIFIED_FAILED')"
        v-if="progress === 'UNAUDITED' && btnList.length > 0"
      >
        驳回
      </button>
      <button
        class="oa-button-apply"
        @click="save('APPROVED')"
        v-if="progress === 'UNAUDITED' && btnList.length > 0"
      >
        批准
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'operate-btn',
  props: {
    isAdd: Number,
    progress: String,
    approveRecordUsers: Array,
    CC: {
      type: Array,
      default: () => []
    },
    Approval: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      btnList: []
    }
  },
  computed: {
    ...mapState('user', ['userInfo'])
  },
  methods: {
    save(progress) {
      this.$emit('save', progress)
    }
  },
  created() {},
  mounted() {
    console.log(this.isAdd, this.approveRecordUsers, this.progress)
    if (this.isAdd === 1) {
      switch (this.progress) {
        case 'UNSUBMENT':
          this.btnList = ['申请', '删除']
          break
        case 'UNAUDITED':
          for (var j = 0; j < this.approveRecordUsers.length; j++) {
            if (this.approveRecordUsers[j].type === 'APPROVER') {
              const userId = this.approveRecordUsers[j].user
                ? this.approveRecordUsers[j].user.id
                : this.approveRecordUsers[j].userId
              if (userId === this.userInfo.id) {
                this.btnList = ['批准', '驳回']
                break
              } else {
                this.btnList = []
              }
            }
          }
          break
        case 'APPROVED':
          this.btnList = []
          break
        case 'CERTIFIED_FAILED':
          this.btnList = ['申请', '删除']
          break
      }
    }
  }
}
</script>

<style lang="stylus">
.operate-btn
  position relative
  & .oa-add-invoice-bottom
    width 100%
    background #FFFFFF
    position fixed
    bottom 0px
    right 0px
    padding 10px 20px
    box-sizing border-box
    display flex
    flex-direction row
    justify-content flex-end
    button
      width 80px
      height 50px
      border-radius 5px
      font-size 16px
    & .oa-button-save
      background none
      border none
      color #0079FE
    & .oa-button-apply
      background #0079FE
      border none
      color #FFFFFF
    & .oa-button-delete
      background none
      border 1px solid #0079FE
      color #0079FE
</style>
