var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "operate-btn" }, [
    _vm.isAdd === 0 &&
    _vm.progress !== "APPROVED" &&
    _vm.Approval.length > 0 &&
    _vm.CC.length > 0
      ? _c("div", { staticClass: "oa-add-invoice-bottom" }, [
          _c(
            "button",
            {
              staticClass: "oa-button-apply",
              on: {
                click: function ($event) {
                  return _vm.save("UNAUDITED")
                },
              },
            },
            [_vm._v("申请")]
          ),
        ])
      : _vm._e(),
    _vm.isAdd === 1 && _vm.progress !== "APPROVED"
      ? _c("div", { staticClass: "oa-add-invoice-bottom" }, [
          _vm.progress === "UNSUBMENT" || _vm.progress === "CERTIFIED_FAILED"
            ? _c(
                "button",
                {
                  staticClass: "oa-button-delete",
                  on: {
                    click: function ($event) {
                      return _vm.save("DELETE")
                    },
                  },
                },
                [_vm._v(" 删除 ")]
              )
            : _vm._e(),
          _vm.progress === "UNSUBMENT" || _vm.progress === "CERTIFIED_FAILED"
            ? _c(
                "button",
                {
                  staticClass: "oa-button-apply",
                  on: {
                    click: function ($event) {
                      return _vm.save("UNAUDITED")
                    },
                  },
                },
                [_vm._v(" 申请 ")]
              )
            : _vm._e(),
          _vm.progress === "UNAUDITED" && _vm.btnList.length > 0
            ? _c(
                "button",
                {
                  staticClass: "oa-button-save",
                  on: {
                    click: function ($event) {
                      return _vm.save("CERTIFIED_FAILED")
                    },
                  },
                },
                [_vm._v(" 驳回 ")]
              )
            : _vm._e(),
          _vm.progress === "UNAUDITED" && _vm.btnList.length > 0
            ? _c(
                "button",
                {
                  staticClass: "oa-button-apply",
                  on: {
                    click: function ($event) {
                      return _vm.save("APPROVED")
                    },
                  },
                },
                [_vm._v(" 批准 ")]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }